import React from "react";

import { useRouter } from "next/router";

import { FormType } from "@/components/forms/";
import { TRACKING_PAYLOADS } from "@/constants/tracking";
import { endpoints } from "@/endpoints";
import { useTrackingEvent } from "@/hooks/tag-manager";

interface NewsletterSignupReturnValue {
	error: boolean;
	loading: boolean;
	subscribe: (email: string) => Promise<void>;
	success: boolean;
}

export const useNewsletterSignup = (): NewsletterSignupReturnValue => {
	const { locale } = useRouter();

	const [success, setSuccess] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);

	const trackEvent = useTrackingEvent(TRACKING_PAYLOADS[FormType.NEWSLETTER]);

	const subscribe = React.useCallback(
		async (email: string) => {
			setLoading(true);

			try {
				const options = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						locale,
						data: {
							profile: {
								data: {
									type: "profile",
									attributes: {
										email,
									},
								},
							},
						},
					}),
				};

				const response = await fetch(endpoints.api.newsletter.baseUrl(), options);

				if (!response.ok) {
					throw new Error("Something went wrong while subscribing");
				}

				setSuccess(true);
				trackEvent();
			} catch (error: unknown) {
				setError(true);
				setSuccess(false);
				console.error("Newsletter Signup", error);
			} finally {
				setLoading(false);
			}
		},
		[trackEvent]
	);

	return {
		error,
		loading,
		subscribe,
		success,
	};
};
