import type { CSSProperties } from "react";

import type { Transition } from "framer-motion";

import { FONT_WEIGHT } from "./typography";

type Shade =
	| "0"
	| "25"
	| "50"
	| "100"
	| "200"
	| "300"
	| "400"
	| "500"
	| "600"
	| "700"
	| "800" // Include as needed
	| "900"
	| "1000";

type ColorShades = {
	[K in Shade]?: string;
};

export interface Palette {
	blue?: ColorShades;
	freeze?: ColorShades;
	green?: ColorShades;
	lightBlue?: ColorShades;
	lime?: ColorShades;
	orange?: ColorShades;
	pink?: ColorShades;
	purple?: ColorShades;
	red?: ColorShades;
	rose?: ColorShades;
	teal?: ColorShades;
	yellow?: ColorShades;
}

// These values are selectable in the Contentful UI
export enum BackgroundColor {
	black = "black",
	white = "white",
	grey = "backgroundGrey",
}

export interface Spaces {
	xxxxs: number;
	xxxs: number;
	xxs: number;
	xs: number;
	s: number;
	m: number;
	l: number;
	xl: number;
	xxl: number;
	xxxl: number;
	xxxxl: number;
}

export interface Sizes {
	s: number;
	m: number;
	l: number;
}

export interface GridConfig {
	gutter: Sizes;
	gridPadding: Sizes;
	padding: Sizes;
	maxWidth: number;
	colSpan: Sizes;
	debug?: boolean;
}

export interface Breakpoints {
	s: number;
	m: number;
	l: number;
	xl: number;
}

export interface Mediaqueries {
	s: string;
	m: string;
	l: string;
	xl: string;
}

export interface HeaderHeight {
	s: number;
	m: number;
	l: number;
}

export interface HeaderLayout {
	height: number;
}

export interface Layout {
	header: HeaderLayout;
}

export type TypographyElement = HTMLDivElement;

export enum FontWeight {
	light = FONT_WEIGHT.light,
	regular = FONT_WEIGHT.regular,
	medium = FONT_WEIGHT.medium,
}

export enum TypographyVariant {
	// HEADLINE SANS
	headlineSans2XL = "headlineSans2XL",
	headlineSansXL = "headlineSansXL",
	headlineSansLG = "headlineSansLG",
	headlineSansMD = "headlineSansMD",
	headlineSansSM = "headlineSansSM",
	headlineSansXS = "headlineSansXS",
	headlineSansXXS = "headlineSansXXS",

	// HEADLINE SERIF
	headlineSerif2XL = "headlineSerif2XL",
	headlineSerifXL = "headlineSerifXL",
	headlineSerifLG = "headlineSerifLG",
	headlineSerifMD = "headlineSerifMD",
	headlineSerifSM = "headlineSerifSM",
	headlineSerifXS = "headlineSerifXS",
	headlineSerifXXS = "headlineSerifXXS",

	// BODY, TITLE, CAPTION
	bodyXL = "bodyXL",
	bodyLG = "bodyLG",
	bodyMD = "bodyMD",
	bodySM = "bodySM",
	bodyXS = "bodyXS",

	// LABEL
	labelLG = "labelLG",
	labelMD = "labelMD",
	labelSM = "labelSM",
}

export interface StyledTypographyProps extends PropsWithTheme<TypographyElement> {
	/** Add types */
	/** @default TypographyVariant.body */
	alignRight?: boolean;
	bottom?: boolean;
	centered?: boolean;
	centeredOnMobile?: boolean;
	component?: keyof JSX.IntrinsicElements;
	dark?: boolean;
	light?: boolean;
	tight?: boolean;
	top?: boolean;
	variant?: TypographyVariant;
	weight?: FontWeight;
}

interface Elevation {
	bottom: Record<"XS" | "SM" | "MD" | "LG", string>;
	left: Record<"LG", string>;
}

interface Animation {
	duration: Record<"SM", string>;
	ease: {
		smooth: Record<"normal" | "accelerated", string>;
	};
	framer: Record<"acceleratedSM", Transition>;
}

export interface Tokens {
	borderRadius: Record<"rounded" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm", string>;
	border: Record<"insetRegular" | "insetIncreased", string>;
	boxShadow: Record<"MD", string>;
	elevation: Elevation;
	animation: Animation;
}

export interface TypographyVariants {
	headlines: {
		sans: Record<"2XL" | "XL" | "LG" | "MD" | "SM" | "XS" | "XXS", CSSProperties>;
		serif: Record<"2XL" | "XL" | "LG" | "MD" | "SM" | "XS" | "XXS", CSSProperties>;
	};
	body: Record<"XL" | "LG" | "MD" | "SM" | "XS", CSSProperties>;
	buttonLabels: Record<"LG" | "MD" | "SM", CSSProperties>;
}

export interface Theme {
	breakpoints: Breakpoints;
	grid: GridConfig;
	layout: Layout;
	mq: Mediaqueries;
	palette: Palette;
	spaces: Spaces;
	tokens: Tokens;
	typography: TypographyVariants;
}

export interface PropsWithTheme<T = HTMLDivElement> extends React.HTMLAttributes<T> {
	/** The theme is injected via a ThemeProvider */
	theme?: Theme;
}
