export const BACKEND_PROXY_API_ROUTE = "api/backend-proxy";

/**
 * Supports Next.js client/server calls against self API route
 * From Client - relative path is required
 * From Server - absolute path with base url is required
 */
const getBaseUrl = () => {
	if (typeof window === "undefined") {
		const port = process.env.NODE_ENV === "development" ? process.env.PORT : "3000";
		// Server-side: use an absolute URL
		return `http://localhost:${port}/${BACKEND_PROXY_API_ROUTE}`;
	} else {
		// Client-side: use a relative URL
		return `/${BACKEND_PROXY_API_ROUTE}`;
	}
};

const baseUrl = getBaseUrl();

export const endpoints = {
	api: {
		newsletter: {
			baseUrl() {
				return "/api/newsletter";
			},
		},
		valuation: {
			baseUrl() {
				return "/api/valuation";
			},
		},
		greenhouse: {
			baseUrl() {
				return "/api/greenhouse";
			},
		},
	},
	greenhouse: {
		jobBoard: {
			get(includeContent: boolean = true) {
				return `https://boards-api.greenhouse.io/v1/boards/evernest/jobs/?content=${includeContent}`;
			},
		},
		job: {
			get(jobId: string, includeContent: boolean = true, includeQuestions: boolean = false) {
				return `https://boards-api.greenhouse.io/v1/boards/evernest/jobs/${jobId}?content=${includeContent}&questions=${includeQuestions}`;
			},
		},
		jobApplication: {
			post(jobId: string) {
				return `https://boards-api.greenhouse.io/v1/boards/evernest/jobs/${jobId}`;
			},
		},
	},
	klaviyo: {
		clientSubscription: {
			post(PUBLIC_API_KEY: string) {
				return `https://a.klaviyo.com/client/subscriptions/?company_id=${PUBLIC_API_KEY}`;
			},
		},
		createClientEvent: {
			post(PUBLIC_API_KEY: string) {
				return `https://a.klaviyo.com/client/events/?company_id=${PUBLIC_API_KEY}`;
			},
		},
	},
	priceHubble: {
		credentials: {
			baseUrl() {
				return "https://api.pricehubble.com/auth/login/credentials";
			},
		},
		valuationLight: {
			baseUrl() {
				return "https://api.pricehubble.com/api/v1/valuation/property_value_light";
			},
		},
		offerStatistics: {
			baseUrl() {
				return "https://api.pricehubble.com/api/v1/offers/statistics";
			},
		},
	},
	casafari: {
		valuation: {
			baseUrl() {
				return "https://api.casafari.com/v1/valuation/comparables-prices";
			},
		},
	},
	lead: {
		valuation: {
			get(id: string): string {
				return `${baseUrl}/api/lead/valuation/${id}/`;
			},
		},
		valuationRequest: {
			post(): string {
				return `${baseUrl}/api/lead/valuation_request/`;
			},
		},
		valuationRequestUUID: {
			get(id: string): string {
				return `${baseUrl}/api/lead/valuation_request/${id}/`;
			},
		},
		postCode: {
			post(): string {
				return `${baseUrl}/api/lead/we_know_your_buyer/`;
			},
		},
		contactIntent: {
			post(): string {
				return `${baseUrl}/api/lead/contact_intent/`;
			},
		},
		listingRetrieve: {
			get(listingId: string): string {
				return `${baseUrl}/api/lead/listing/${listingId}/`;
			},
		},
		exposeViewed: {
			post(exposeId: string) {
				return `${baseUrl}/api/lead/expose/${exposeId}/viewed/`;
			},
		},
		exposeStatusRetrieve: {
			get(exposeId: string) {
				return `${baseUrl}/api/lead/expose/${exposeId}/info/`;
			},
		},
	},
	rental: {
		rentalListingRetrieve: {
			get(rentalListingId: string): string {
				return `${baseUrl}/api/rental/rental_listing/${rentalListingId}/`;
			},
		},
		termsAccepted: {
			post(rentalRequestId: string) {
				return `${baseUrl}/api/rental/rental_process/${rentalRequestId}/terms_accepted/`;
			},
		},
	},
};
