import { FormType } from "@/components/forms/";

export const TRACKING_PAYLOADS = {
	/* @ToDo: replace with actual SELECTED payload later. BUYER is ok for now - Stefan */
	[FormType.SELECTED]: {
		"Form Name": "Main Form Buyers",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_buyers_form_main",
		"Event Label": "buyers",
	},
	[FormType.BUYER]: {
		"Form Name": "Main Form Buyers",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_buyers_form_main",
		"Event Label": "buyers",
	},
	[FormType.SELLER]: {
		"Form Name": "Main Form Sellers",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_sellers_form_main",
		"Event Label": "sellers",
		event: "contactForm",
	},
	[FormType.EXPOSE_REQUEST]: {
		"Form Name": "Main Form Expose",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_expose_form_main",
		"Event Label": "expose",
		event: "contactForm",
	},
	[FormType.AGENT]: {
		"Form Name": "Main Form Find Agents",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_find_agents_form_main",
		"Event Label": "find-agents",
	},
	[FormType.APPLY_AS_AGENT]: {
		"Form Name": "Main Form Career",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_career_form_main",
		"Event Label": "agents",
		event: "contactForm",
	},
	[FormType.APPLY_AS_ALL]: {
		"Form Name": "Main Form Career",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "01_career_form_main",
		"Event Label": "all-jobs",
		event: "contactForm",
	},
	[FormType.NEWSLETTER]: {
		"Form Name": "Newsletter",
		"Event Category": "conversion",
		"Event Action": "marketing_conversion",
		"Event Label": "general",
		"Event Name": "01_marketing_sign-up",
		event: "newsletterForm",
	},
	[FormType.VALUATION]: {
		"Event Category": "conversion",
		"Event Action": "valuation_submission",
		"Event Label": "info",
		event: "valuationForm",
	},
	[FormType.VALUATION_FUNNEL]: [
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_0_start",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_1_propertytype",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_2_propertydata",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_3_reason",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_1.5_propertycondition",
			"Event Label": "",
			event: "valuationFunnel",
		},
	],
	sliders: {
		"Event Category": "SlideShow",
		"Event Action": "slide",
		event: "slideshowsNavigation",
	},
	printWebExpose: {
		"Event Category": "WebExpose",
		"Event Action": "click",
		event: "printWebExposeClicked",
	},
	[FormType.POSTCODE_VALUATION_FUNNEL]: {
		submitPostcode: {
			"Event Category": "We Know Your Buyer",
			"Event Action": "we_know_your_buyer_1_start",
			event: "postcodeValuationFunnel",
		},
		getInTouch: {
			"Event Category": "We Know Your Buyer",
			"Event Action": "we_know_your_buyer_2_buyers",
			event: "postcodeValuationFunnel",
		},
		submitPersonalInfo: {
			"Event Category": "We Know Your Buyer",
			"Event Action": "we_know_your_buyer_3_contact_details",
			event: "postcodeValuationFunnel",
		},
	},
	[FormType.PARTNER]: {
		"Form Name": "Main Form License Partners",
		"Event Category": "conversion",
		"Event Action": "contact_form_conversion",
		"Event Name": "licensepartner_form_main",
		"Event Label": "licensepartner",
		event: "contactForm",
	},
};
